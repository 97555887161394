export default {
    props: {
        parent: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            errors: {}
        }
    }
}
