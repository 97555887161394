<template>
    <component
        :is="formComponent"
        :parent="parent"
        @loading="onLoading"
        @sequence="onSequence"
        @success="onSuccess"
        @cancel="$emit('cancel')"
    />
</template>

<script>
import uploadFormMixin from '@apps/media/components/MediaUploadForm/mixins/uploadFormMixin'
import { mediaTypes } from '@apps/media/tools/config'

export default {
    name: 'MediaUploadForm',
    mixins: [ uploadFormMixin ],
    props: {
        mediaType: {
            type: String,
            default: 'link'
        }
    },
    components: {
        UploadFileForm: () => import('./UploadFileForm'),
        UploadYoutubeForm: () => import('./UploadYoutubeForm'),
        UploadLinkForm: () => import('./UploadLinkForm'),
        UploadGoogleDriveForm: () => import('./UploadGoogleDriveForm'),
        CreateFolderForm: () => import('./CreateFolderForm'),
    },
    computed: {
        formComponent() {
            if(this.mediaType === mediaTypes.FOLDER) {
                return 'create-folder-form'
            }

            const availableComponents = [ 'link', 'youtube', 'file', 'google-drive' ]

            return availableComponents.includes(this.mediaType)
                ? `upload-${ this.mediaType }-form`
                : 'upload-link-form'
        }
    },
    data() {
        return {}
    },
    methods: {
        onLoading(data) {
            this.$emit('loading', data)
        },
        onSequence(data) {
            this.$emit('sequence', data)
        },
        onSuccess(data) {
            this.$emit('success', data)
        }
    }
}
</script>

<style lang=scss>

</style>
