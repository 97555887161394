<template>
    <w-dialog-drawer
        v-model="isDrawer"
        :title="dialogTitle"
        :icon="dialogIcon"
        :persistent="isMultipleForm"
        width="600"
    >
        <div class="pa-4">
            <media-upload-form
                :key="componentKey"
                :media-type="mediaType"
                :parent="parent"
                @loading="onLoading"
                @sequence="onSequence"
                @success="onSuccess"
                @cancel="onCancel"
            />
            <v-divider v-if="isMultipleForm" class="my-4"/>
            <div v-if="isMultipleForm" class="d-md-flex align-center">
                <v-btn
                    :disabled="!canLoadMore"
                    :block="isMobile"
                    small
                    color="primary"
                    outlined
                    @click="onLoadMore"
                >
                    <w-icon left value="UPLOAD"/>
                    {{ $trans('Load more') }}
                </v-btn>
                <v-spacer class="my-2 my-md-0"/>
                <v-btn
                    :disabled="isLoadingStarted"
                    :block="isMobile"
                    small
                    outlined
                    @click="onDone"
                >
                    <w-icon left value="CLOSE"/>
                    {{ $trans('Close') }}
                </v-btn>
            </div>
        </div>
    </w-dialog-drawer>
</template>

<script>
import MediaUploadForm from '@apps/media/components/MediaUploadForm/index'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'
import { mediaTypes } from '@apps/media/tools/config'

export default {
    name: 'UploadFormDialog',
    mixins: [ mobileBreakpointChecker ],
    components: { MediaUploadForm },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        mediaType: {
            type: String,
            default: null
        },
        parent: {
            type: String,
            default: null
        }
    },
    computed: {
        isMultipleForm() {
            return this.mediaType === mediaTypes.FILE ||
                this.mediaType === 'google-drive'
        },
        dialogIcon() {
            switch (this.mediaType) {
                case mediaTypes.FOLDER:
                    return 'FOLDER_PLUS'
                case mediaTypes.FILE:
                    return 'UPLOAD'
                case mediaTypes.YOUTUBE:
                    return 'YOUTUBE'
                case mediaTypes.LINK:
                    return 'LINK'
                default:
                    return null
            }
        },
        dialogTitle() {
            if(this.isMultipleForm) {
                if(this.isLoadingStarted) {
                    return this.$trans('Loading') +
                        ': ' + this.loadedItems +
                        '/' + this.countItems
                }

                if(this.isLoadingFinished) {
                    return this.$trans('Loading was finished')
                }
            }

            if(this.mediaType === mediaTypes.FILE) {
                return this.$trans('Upload files')
            }

            if(this.mediaType === mediaTypes.YOUTUBE) {
                return this.$trans('Add youtube video')
            }

            if(this.mediaType === mediaTypes.LINK) {
                return this.$trans('Add link')
            }

            if(this.mediaType === mediaTypes.FOLDER) {
                return this.$trans('Create folder')
            }

            return 'Unknown type'
        }
    },
    watch: {
        value(flag) {
            this.isDrawer = flag
        },
        isDrawer(flag) {
            if(flag) {
                this.componentKey++
            }

            if(!flag) {
                this.isLoadingStarted = false
                this.isLoadingFinished = false
                this.canLoadMore = false
            }

            this.$emit('input', flag)
        }
    },
    data() {
        return {
            componentKey: 0,
            isDrawer: false,
            isLoadingStarted: false,
            isLoadingFinished: false,
            canLoadMore: false,
            countItems: 0,
            loadedItems: 0
        }
    },
    methods: {
        onLoading(total) {
            this.countItems = total
            this.isLoadingStarted = true
        },
        onSequence() {
            this.loadedItems++
        },
        onSuccess() {
            this.isLoadingStarted = false
            this.isLoadingFinished = true
            this.canLoadMore = true

            if(!this.isMultipleForm) {
                this.isDrawer = false
            }

            this.$emit('success')
        },
        onCancel() {
            this.isDrawer = false

            this.$emit('cancel')
        },
        onLoadMore() {
            this.countItems = 0
            this.loadedItems = 0
            this.isLoadingStarted = false
            this.isLoadingFinished = false
            this.canLoadMore = false
            this.componentKey++
        },
        onDone() {
            this.countItems = 0
            this.loadedItems = 0
            this.isDrawer = false
            this.$emit('cancel')
        }
    }
}
</script>

<style lang=scss>

</style>
